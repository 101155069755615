.Dropzone {
  height: 50px;
  width: 100%;
  background-color: #f7f7f7;
  /*border: 2px dashed rgb(187, 186, 186);*/
  /*border-radius: 50%;*/
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;
  font-size: 12px;
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none ! important;
}