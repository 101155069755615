.bulk-container {
/*    display: block;
    box-sizing: border-box;
    height: auto;
    position: fixed;
    z-index: 1001;
    background-color: #ffffff;
    box-shadow: 0 0 5px #a7a6a6;
    width: 64%;
    top: 8px;
    left: 16%;
    border: 2px solid #bbbaba;*/


    display: block;
    box-sizing: border-box;
    height: auto;
    width: 50%;
    position: fixed;
    margin: 80px 100px;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
}

.bulk-review-container {
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1002;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 0 5px #a7a6a6;
    border: 2px solid #bbbaba;
}

.bulk-header {
    box-sizing: border-box;
    padding: 20px 10px 20px 30px;
    border-left: 8px solid #36ABA6;
}

.bulk-body {
    background-color: #F1F6F7;
    padding: 30px;
    box-sizing: border-box;
    height: 606px;
    overflow-y: auto;
}

.bulk-footer {
   /* padding: 15px 30px;
    box-sizing: border-box;*/
    position: relative;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: #e9ecef;
/*    height: 79px;*/
    border-radius: 5px;
    padding: 15px 30px;
}

.upload-box {
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    border: 1px dashed #000000;
    background-color: #ffffff;
    border-radius: 3px;
    display: table;
}

.upload-inner-box {
    box-sizing: border-box;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.upload-btn {
    background-color: #1A3553;
    color: #ffffff;
    font-style: italic;
    border: 1px solid #1A3553;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 3px;
    box-sizing: border-box;
}

.save-btn {
    background-color: #37ada7;
    padding: 10px 25px;
    border: 1px solid #37ada7;
    border-radius: 2px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
}

.cancel-btn {
    background-color: #ffffff;
    padding: 10px;
    border: 1px solid #b4b4b4;
    border-radius: 2px;
    color: #666666;
    cursor: pointer;
    display: inline-block;
}

.ext {
    border: 1px solid #cdcaca;
    padding: 7px;
    font-weight: 600;
    height: 19px;
    background-color: #fff;
}

.inactive-overlay {
    display: block;
    box-sizing: border-box;
/*    height: 3000px;*/
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1003;
    /* opacity: 0.6; */
}