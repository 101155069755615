.cs-table {
    border-spacing: 0px;
    width: 100%;
}

.cs-thead {
    background-color: #dfe9f5;
}

.cs-th {
    font-size: 11px;
    text-align: left;
    padding: 15px 10px 15px 15px;
    border-left: 1px solid #c3c1c1;
    border-top: 1px solid #c3c1c1;
    border-bottom: 1px solid #c3c1c1;
}

.cs-td {
    font-size: 11px;
    font-weight: 600;
    text-align: left;
    padding: 15px 10px 15px 15px;
    background-color: #fff;
    border-left: 1px solid #c3c1c1;
    border-bottom: 1px solid #c3c1c1;
}

.cs-td-last {
    font-size: 11px;
    font-weight: 600;
    text-align: left;
    background-color: #fff;
    border-left: 1px solid #c3c1c1;
    border-right: 1px solid #c3c1c1;
    border-bottom: 1px solid #c3c1c1;
}